<template>
  <div>
    <el-dialog v-model="visible" title="" width="480px" :show-close="false" class="asd">
      <div class="model-bg">
        <img src="@/assets/images/login-logo.png" alt="" class="login-logo" />
        <div class="login-box" v-if="loginVisible">
          <div class="close-icon" @click="closeClick">
            <img src="@/assets/images/closable-icon-grey.png" alt="" />
          </div>
          <div class="tabs">
            <div class="login-tab" @click="tabClick(0)">
              <p :class="tabCurrent == 0 ? 'tabs-font' : ''">验证码登录</p>
              <img src="@/assets/images/line.png" alt="" v-if="tabCurrent == 0" />
            </div>
            <div class="login-tab" @click="tabClick(1)">
              <p :class="tabCurrent == 1 ? 'tabs-font' : ''">账号密码登录</p>
              <img src="@/assets/images/line.png" alt="" v-if="tabCurrent == 1" />
            </div>
          </div>
          <div class="code-login-form" v-if="tabCurrent == 0">
            <a-input
              type="text"
              placeholder="请填写手机号"
              class="phone-input"
              v-model:value="mobile"
            />
            <div class="code-wrap">
              <a-input
                type="text"
                placeholder="请填写验证码"
                class="code-input"
                v-model:value="code"
              />
              <div class="code-btn" @click="getCode('YZMDL')" v-if="codeShow">获取验证码</div>
              <div class="code-btn" v-else>{{ codeText }}</div>
            </div>
          </div>
          <div class="account-login-form" v-if="tabCurrent == 1">
            <a-input type="text" placeholder="请填写手机号" v-model:value="mobile" />
            <a-input type="password" placeholder="请填写密码" v-model:value="password" />
            <div class="">
              <p @click="registerClick(1)">忘记密码</p>
              <p @click="registerClick(2)">注册账号</p>
            </div>
          </div>
          <div class="login-btn" @click="submit(1)">登录</div>
          <div class="protocol-box">
            <img
              src="@/assets/images/checked-icon-1.png"
              alt=""
              @click="checkedClick"
              v-if="checked == false"
            />
            <img
              src="@/assets/images/checked-icon-2.png"
              alt=""
              @click="checkedClick"
              v-if="checked == true"
            />
            <div>
              <text>登录即代表您同意</text>
              <text style="color: #0cb6ff; cursor: pointer" @click="gopage(1)">《用户协议》</text>
              <text style="color: #0cb6ff; cursor: pointer" @click="gopage(2)">《隐私政策》</text>
            </div>
          </div>
        </div>
        <div v-if="registerVisible" class="register-box">
          <div class="close-icon" @click="closeClick">
            <img src="@/assets/images/closable-icon-grey.png" alt="" />
          </div>
          <div class="register-title">
            <p>{{ registerTitle }}</p>
            <img src="@/assets/images/line.png" alt="" />
          </div>
          <div class="" style="margin: 0 32px">
            <a-input placeholder="请填写手机号" v-model:value="mobile" />
            <div class="code-wrap">
              <a-input
                type="text"
                placeholder="请填写验证码"
                class="code-input"
                v-model:value="code"
              />
              <div class="code-btn" @click="getCode('type')" v-if="codeShow">获取验证码</div>
              <div class="code-btn" v-else>{{ codeText }}</div>
            </div>
            <a-input type="password" placeholder="请填写密码" v-model:value="password" />
            <a-input
              type="password"
              placeholder="确认登录密码"
              v-model:value="confirmPassword"
              @blur="checkPassword($event)"
              @change="confirmPasswordChange"
            />
            <p class="tips" v-if="passwordStatus">两次密码输入不一致</p>
          </div>
          <div class="btn" @click="submit(2)">提交并登录</div>
          <div style="text-align: center; margin-top: 16px">
            <text style="font-size: 14px; color: #929299" v-if="registerTitle === '注册账号'">
              已有账号，
            </text>
            <text
              style="font-size: 14px; color: #0cb6ff; cursor: pointer"
              @click="loginVisibleClick"
            >
              {{ registerText }}
            </text>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose, watch, inject } from 'vue'
import { ElMessage } from 'element-plus'
import { register, findPwd, login, sendCode } from '@/api/user'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { pa } from 'element-plus/es/locale'
const route = useRoute()
const router = useRouter()
const store = useStore()
const reload = inject('reload')
const tabCurrent = ref(0)
const visible = ref(false)
const checked = ref(false)
const loginVisible = ref(true)
const registerVisible = ref(false)
const registerTitle = ref('')
const registerStatus = ref(2)
const registerText = ref('')
const passwordStatus = ref(false)
const mobile = ref('')
const password = ref('')
const code = ref('')
const confirmPassword = ref('')
const codeText = ref('')
const codeShow = ref(true)
const count = ref(60)
const timer = ref(null)
function closeClick() {
  visible.value = false
  registerVisible.value = false
  store.commit('login/loginVisible', false)
}
function tabClick(index) {
  tabCurrent.value = index
  mobile.value = ''
  password.value = ''
  code.value = ''
}
function checkedClick() {
  checked.value = !checked.value
}
function registerClick(e) {
  if (e === 1) {
    registerTitle.value = '忘记密码'
    registerText.value = '去登录'
  } else if (e === 2) {
    registerTitle.value = '注册账号'
    registerText.value = '直接登录'
  }
  mobile.value = ''
  password.value = ''
  code.value = ''
  registerStatus.value = e
  loginVisible.value = false
  registerVisible.value = true
}
function loginVisibleClick() {
  loginVisible.value = true
  registerVisible.value = false
}
function checkPassword(e) {
  console.log(e)
}
function confirmPasswordChange() {
  passwordStatus.value = false
}
function submit(e) {
  if (e === 1) {
    if (tabCurrent.value === 0) {
      if (!mobile.value) {
        ElMessage.warning('请输入手机号')
        return false
      }
      if (!code.value) {
        ElMessage.warning('请输入验证码')
        return false
      }
      if (!checked.value) {
        ElMessage.warning('请勾选用户协议和隐私政策')
        return true
      }
      const params = {
        mobile: mobile.value,
        code: code.value,
        type: '1'
      }
      login(params).then((res) => {
        if (res.code === 200) {
          ElMessage.success('登陆成功')
          localStorage.setItem('token', res.data.token)
          store.commit('user/setUser', res.data)
          store.commit('user/setLoginStatus', false)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          reload()
          checked.value = false
          visible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else if (tabCurrent.value === 1) {
      if (!mobile.value) {
        ElMessage.warning('请输入手机号')
        return false
      }
      if (!password.value) {
        ElMessage.warning('请输入密码')
        return false
      }
      if (!checked.value) {
        ElMessage.warning('请勾选用户协议和隐私政策')
        return true
      }
      const params = {
        mobile: mobile.value,
        password: password.value,
        type: '0'
      }
      login(params).then((res) => {
        if (res.code === 200) {
          ElMessage.success('登陆成功')
          localStorage.setItem('token', res.data.token)
          store.commit('user/setUser', res.data)
          store.commit('user/setLoginStatus', false)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          reload()
          checked.value = false
          visible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
  } else if (e === 2) {
    if (!mobile.value) {
      ElMessage.warning('请输入手机号')
      return false
    }
    if (!code.value) {
      ElMessage.warning('请输入验证码')
      return false
    }
    if (!password.value) {
      ElMessage.warning('请输入密码')
      return false
    }
    if (!confirmPassword.value) {
      ElMessage.warning('请重复密码')
      return false
    }
    if (password.value !== confirmPassword.value) {
      passwordStatus.value = true
      return false
    }
    const params = {
      mobile: mobile.value,
      code: code.value,
      password: password.value
    }
    if (registerStatus.value === 2) {
      register(params).then((res) => {
        if (res.code === 200) {
          ElMessage.success('注册登录成功')
          localStorage.setItem('token', res.data.token)
          store.commit('user/setUser', res.data)
          store.commit('user/setLoginStatus', false)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          reload()
          visible.value = false
          registerVisible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else if (registerStatus.value === 1) {
      findPwd(params).then((res) => {
        if (res.code === 200) {
          ElMessage.success('密码修改成功')
          localStorage.setItem('token', res.data.token)
          visible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
  }
}
function getCode(e) {
  let sceneType = null
  if (!mobile.value) {
    ElMessage.warning('请输入手机号')
    return false
  }
  if (e === 'type') {
    if (registerStatus.value === 2) {
      sceneType = 'ZCZH'
    } else if (registerStatus.value === 1) {
      sceneType = 'WJMM'
    }
  } else {
    sceneType = e
  }
  sendCode({ mobile: mobile.value, scene: sceneType }).then((res) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)

      if (!timer.value) {
        timer.value = setInterval(() => {
          if (count.value > 0) {
            count.value = count.value - 1
            codeShow.value = false
            codeText.value = '重新获取(' + count.value + 's' + ')'
          } else {
            clearInterval(timer.value)
            codeShow.value = true
            count.value = 60
            codeText.value = ''
            timer.value = null
          }
        }, 1000)
      }
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const gopage = (val) => {
  visible.value = false
  window.open('https://www.sybaopei.com/privary.txt')
  // router.push({ path: '/article', query: { id: val } })
}
onMounted(() => {
  store.commit('login/loginVisible', false)
})
watch(
  () => store.state.login.loginStatus,
  (e) => {
    visible.value = e
  }
)
defineExpose({ visible, registerVisible, loginVisible, registerTitle, registerText })
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  display: none;
}
:deep(.el-dialog__body) {
  border-radius: 20px;
  padding: 0;
}
.model-bg {
  width: 480px;
  //height: 505px;
  //background: url('~@/assets/images/login-bg.png') no-repeat;
  background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
  padding: 12px 0;
  position: relative;
  border-radius: 20px;
  .login-logo {
    position: absolute;
    left: 150px;
    top: -75px;
  }
  .close-icon {
    text-align: right;
    margin-right: 21px;
    cursor: pointer;
  }
  .login-box {
    width: 456px;
    height: 481px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 21px;

    .tabs {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      padding: 0 101px;
      .login-tab {
        text-align: center;
        cursor: pointer;
        p {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
      .tabs-font {
        font-size: 18px !important;
        color: #333333 !important;
      }
    }
    .code-login-form {
      padding: 0 32px;
      margin-top: 25px;
      .phone-input {
        width: 392px;
        height: 50px;
        background: #f6f7fb;
        border-radius: 26px;
        padding-left: 18px;
      }
      .code-wrap {
        display: flex;
        margin-top: 24px;
        .code-input {
          width: 264px;
          height: 50px;
          background: #f6f7fb;
          border-radius: 26px;
          padding-left: 18px;
        }
        .code-btn {
          width: 116px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          border-radius: 26px;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
    .account-login-form {
      padding: 0 32px;
      //   margin-top: 25px;
      input {
        width: 392px;
        height: 50px;
        background: #f6f7fb;
        border-radius: 26px;
        padding-left: 18px;
        margin-top: 25px;
      }
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #929299;
          cursor: pointer;
        }
        p:nth-child(2) {
          color: #0cb6ff;
        }
      }
    }
    .login-btn {
      width: 392px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      border-radius: 26px;
      background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
      margin: 0 auto;
      margin-top: 54px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .protocol-box {
      display: flex;
      align-items: center;
      margin-top: 16px;
      padding: 0 32px;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 5px;
      }
      text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #929299;
      }
    }
  }
  .register-box {
    width: 456px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 21px;
    padding-bottom: 36px;
    .register-title {
      text-align: center;
      margin-top: 30px;
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    input {
      width: 392px;
      height: 50px;
      background: #f6f7fb;
      border-radius: 26px;
      padding-left: 18px;
      margin-top: 24px;
    }
    .code-wrap {
      display: flex;
      .code-input {
        width: 264px;
        height: 50px;
        background: #f6f7fb;
        border-radius: 26px;
        padding-left: 18px;
      }
      .code-btn {
        width: 116px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border-radius: 26px;
        margin-left: 12px;
        cursor: pointer;
        margin-top: 24px;
      }
    }
    .tips {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff3636;
      margin-top: 12px;
      text-align: right;
    }
    .btn {
      width: 392px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      border-radius: 26px;
      background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
      margin: 0 auto;
      margin-top: 34px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
